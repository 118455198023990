import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const FooterTadDutyFreeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  color: var(--tad-green);
  padding: 1rem 0;
  margin-top: 1rem;
  @media (max-width: 768px) {
    display: block;
    text-align: center;
  }
`;

const FooterTadDutyFree = () => {
  const [year, setYear] = useState(0);
  useEffect(() => {
    const date = new Date();
    setYear(date.getFullYear());
  }, []);
  return <FooterTadDutyFreeWrapper>{year} TAD Duty Free - All rights reserved.</FooterTadDutyFreeWrapper>;
};

export default FooterTadDutyFree;
