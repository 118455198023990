import React, { useEffect } from 'react';
import styled from 'styled-components';
import HeroTadDutyFree from '../Hero/HeroTadDutyFree';
import HeaderTadDutyFree from '../Header/HeaderTadDutyFree';
import FooterTadDutyFree from '../Footer/FooterTadDutyFree';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../SEO/seo';

const HeroTadDutyFreeWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  color: var(--tad-green);
  width: 100%;
  overflow: hidden;
  position: relative;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

const Title = styled.h1`
  font-size: 2.525rem;
  margin: 2rem 0;
  text-align: left;
  font-weight: bold;
`;

const Text = styled.p`
  font-size: 1.325rem;
  margin: 1.325rem 0;
  text-align: left;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 3rem 2rem 8rem 2rem;
`;

const Tagline = styled.p`
  font-size: 4.325rem;
  line-height: 1;
  color: var(--tad-red);
  margin: 1.325rem 0;
  text-align: center;
  font-weight: 800;
  padding: 0 4rem;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  img:nth-child(1) {
    width: 30%;
    height: auto;
    padding: 0.5rem;
  }

  img:nth-child(2) {
    width: 40%;
    height: auto;
    padding: 0.5rem;
  }

  img:nth-child(3) {
    width: 30%;
    height: auto;
    padding: 0.5rem;
  }
`;

const TadDecorationContainer = styled.div`
  position: absolute;
  top: 20%;
  right: -5%;
  width: 100%;
  z-index: -1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  opacity: 0.3;
`;

const TadDecoration = () => {
  return (
    <TadDecorationContainer>
      <StaticImage src="../../images/tad/decoration2.svg" placeholder="blurred" />
    </TadDecorationContainer>
  );
};

const TadContent = ({ selectedMenu, heroContent, children }) => {
  useEffect(() => {
    console.log('TadContent: selectedMenu:', selectedMenu);
    console.log('TadContent: heroContent:', heroContent);
  }, [heroContent, selectedMenu]);

  return (
    <HeroTadDutyFreeWrapper>
      <SEO
        title="TAD Duty Free"
        description="Your ultimate gateway to a shopping paradise that transforms your waiting hours into an adventure of discovery and indulgence before you embark on your next journey."
        image="https://optimum-filter.com/__02_IMAGE/of_facebook.png"
      />
      <HeaderTadDutyFree />
      <HeroTadDutyFree page={selectedMenu} />
      {children}
      <FooterTadDutyFree />
      <TadDecoration />
    </HeroTadDutyFreeWrapper>
  );
};

export default TadContent;
