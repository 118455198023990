import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import TadLogo from '../../images/tad/TDF_logo.png';
import { Link } from 'gatsby';

const HeaderTadDutyFreeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  height: 120px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  @media (max-width: 768px) {
    height: 80px;
  }
`;

const HeaderElements = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

const MenuMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const MenuMobileElement = styled.div`
  margin: 1rem 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-weight: 900;
  padding: 0.525rem 0.725rem;
  color: white;
  font-size: 1.5rem;
  a {
    color: white;
  }
`;

const Logo = styled.div`
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 0.525rem;
  @media (max-width: 768px) {
    /* display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
    background: url('../../images/tad/TDF_logo.png') no-repeat center; */
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 300px;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .st0 {
    fill: none;
    stroke: #000000;
    stroke-width: 66.6667;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 133.3333;
  }
  .mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }
`;

const MenuElement = styled.div`
  margin: 0 0.825rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-weight: 900;
  padding: 0.525rem 0.725rem;
  a {
    color: var(--tad-green);
  }
  a.active-link {
    border-bottom: 4px solid var(--tad-green);
  }
  a:hover {
    color: var(--tad-green);
    opacity: 0.8;
  }
  &.mobile {
    text-align: center;
    padding: 1.5rem 0;
    font-size: 1.5rem;
    color: white;
  }
  @media (max-width: 768px) {
    margin: 0 0.525rem;
    padding-top: 2rem;
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SocialElement = styled.div`
  margin: 0 1rem;
  cursor: pointer;
  width: 40px;
`;

const MenuIconWrapper = styled.div`
  display: block;
  width: 32px;
  height: 32px;
`;

const MobileMenuWrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--tad-green);
  z-index: 100;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileMenuClose = styled.div`
  position: absolute;
  top: 1.75rem;
  right: 1rem;
  cursor: pointer;
  z-index: 3;
  width: 24px;
  height: 24px;
`;

const menuElements = [
  {
    name: 'Brands',
    isActive: false,
  },
  {
    name: 'Partnerships',
    isActive: false,
  },
  {
    name: 'Shops',
    isActive: false,
  },
  {
    name: 'Optimum-Filter',
    isActive: false,
    isExternal: true,
  },
];

const CloseButton = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 800 800"
      style={{ enableBackground: 'new 0 0 800 800' }}
    >
      <style type="text/css">
        {`.st0{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:33.3333;stroke-miterlimit:133.3333;}`}
      </style>
      <path
        className="st0"
        d="M691.5,111c-13-13-34.1-13-47.1,0L401.2,354.1L158.1,111c-13-13-34.1-13-47.1,0c-13,13-13,34.1,0,47.1
    l243.1,243.1L111,644.4c-13,13-13,34.1,0,47.1c13,13,34.1,13,47.1,0l243.1-243.1l243.1,243.1c13,13,34.1,13,47.1,0
    c13-13,13-34.1,0-47.1L448.4,401.2l243.1-243.1C704.5,145.1,704.5,124,691.5,111z"
      />
    </svg>
  );
};

const HeaderTadDutyFree = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <HeaderTadDutyFreeWrapper>
      <Container>
        <HeaderElements>
          <Logo>
            <Link to="/taddutyfree" activeClassName="active-link">
              <img width={500} src={TadLogo} alt="Tad Duty Free" />
            </Link>
          </Logo>
          <Menu className="d-none d-lg-flex">
            <MenuElement>
              <Link to="/taddutyfree/brands" activeClassName="active-link">
                Brands
              </Link>
            </MenuElement>
            <MenuElement>
              <Link to="/taddutyfree/partnerships" activeClassName="active-link">
                Partnerships
              </Link>
            </MenuElement>
            <MenuElement>
              <Link to="/taddutyfree/shops" activeClassName="active-link">
                Shops
              </Link>
            </MenuElement>
            <MenuElement>
              <a href="https://optimum-filter.com/" target="_blank" rel="noreferrer">
                Optimum-Filter
              </a>
            </MenuElement>
          </Menu>
          <Menu className="d-block d-lg-none d-xl-none">
            <MenuIconWrapper onClick={handleMobileMenu}>
              <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 800 800">
                <g id="SVGRepo_iconCarrier">
                  <path className="st0" d="M133.3,200h533.3 M133.3,400h533.3 M133.3,600h533.3" />
                </g>
              </svg>
            </MenuIconWrapper>
          </Menu>
        </HeaderElements>
      </Container>
      {isMobileMenuOpen && (
        <MobileMenuWrapper className="animate__animated animate__fadeInDown animate__faster">
          <MobileMenuClose onClick={handleMobileMenu}>
            <CloseButton />
          </MobileMenuClose>
          <MenuMobile className="mobile">
            <MenuMobileElement>
              <Link to="/taddutyfree" activeClassName="active-link">
                Home
              </Link>
            </MenuMobileElement>
            <MenuMobileElement>
              <Link to="/taddutyfree/brands" activeClassName="active-link">
                Brands
              </Link>
            </MenuMobileElement>
            <MenuMobileElement>
              <Link to="/taddutyfree/partnerships" activeClassName="active-link">
                Partnerships
              </Link>
            </MenuMobileElement>
            <MenuMobileElement>
              <Link to="/taddutyfree/shops" activeClassName="active-link">
                Shops
              </Link>
            </MenuMobileElement>
            <MenuMobileElement>
              <a href="https://optimum-filter.com/" target="_blank" rel="noreferrer">
                Optimum-Filter
              </a>
            </MenuMobileElement>
          </MenuMobile>
        </MobileMenuWrapper>
      )}
    </HeaderTadDutyFreeWrapper>
  );
};

export default HeaderTadDutyFree;
