import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const HeroTadDutyFreeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  color: var(--tad-green);
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    height: 200px;
    .gatsby-image-wrapper-constrained {
      height: 100%;
    }
  }
`;

const HeroTadDutyFreeWrapperSub = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  color: var(--tad-green);
  width: 100%;
  position: relative;
  max-height: 350px;
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 20%, rgba(0, 212, 255, 0) 100%);
  z-index: 1;
`;

const Tagline = styled.p`
  font-size: 3.325rem;
  line-height: 1;
  color: var(--tad-red);
  margin: 1.325rem 0;
  font-weight: 800;
  z-index: 2;
  position: absolute;
  font-family: 'Open Sans', sans-serif;
  margin-left: 5rem;
  .italic {
    font-family: 'Marck Script', cursive;
    font-size: 5.325rem;
    @media (max-width: 768px) {
      font-size: 2.925rem;
    }
  }
  @media (max-width: 768px) {
    font-size: 1.925rem;
    line-height: 2.7rem;
    padding: 0 2rem;
    margin-left: 0;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 150px;
    .gatsby-image-wrapper-constrained {
      height: 100%;
    }
  }
`;

const HeroMain = () => {
  const breakpoints = useBreakpoint();

  return (
    <HeroTadDutyFreeWrapper>
      <Overlay>
        <Container className="d-flex align-items-center justify-content-start h-100 w-100">
          <Tagline className="animate__animated animate__slideInLeft">
            <span>Your Journey,</span>
            <br />
            <span className="italic">Your Joy</span>
          </Tagline>
        </Container>
      </Overlay>
      {!breakpoints.sm && (
        <StaticImage
          className="animate__animated animate__fadeIn"
          src="../../images/tad/tad_hero.png"
          alt="TAD Duty Free"
        />
      )}
      {breakpoints.sm && (
        <StaticImage
          className="animate__animated animate__fadeIn"
          src="../../images/tad/tad_hero.png"
          alt="TAD Duty Free"
          height={300}
          fluid
        />
      )}
    </HeroTadDutyFreeWrapper>
  );
};

const HeroSubpage = ({ page }) => {
  return (
    <HeroTadDutyFreeWrapperSub>
      <Overlay>
        <Container className="d-flex align-items-center justify-content-start h-100 w-100">
          <Tagline className="animate__animated animate__slideInLeft">
            <span>{page}</span>
          </Tagline>
        </Container>
      </Overlay>
      <ImageContainer className="animate__animated animate__fadeIn">
        {page === 'Brands' ? (
          <StaticImage
            imgStyle={{ objectPosition: 'center center' }}
            src="../../images/tad/tad_hero_brands.png"
            alt="TAD Duty Free"
            layout="constrained"
          />
        ) : page === 'Shops' ? (
          <StaticImage
            layout="constrained"
            imgStyle={{ objectPosition: 'center center' }}
            src="../../images/tad/tad_hero_shops.png"
            alt="TAD Duty Free"
          />
        ) : page === 'Partnerships' ? (
          <StaticImage
            layout="constrained"
            imgStyle={{ objectPosition: 'center center' }}
            src="../../images/tad/tad_hero_partnerships.png"
            alt="TAD Duty Free"
          />
        ) : null}
      </ImageContainer>
    </HeroTadDutyFreeWrapperSub>
  );
};

const HeroTadDutyFree = ({ heroContent, page }) => {
  useEffect(() => {
    console.log('HeroTadDutyFree: heroContent:', heroContent);
  }, [heroContent]);

  if (page === 'home') {
    return <HeroMain />;
  } else {
    return <HeroSubpage page={page} />;
  }
};

export default HeroTadDutyFree;

HeroTadDutyFree.propTypes = {
  heroContent: PropTypes.array,
  page: PropTypes.string,
};
HeroTadDutyFree.defaultProps = {
  heroContent: [],
};

HeroSubpage.propTypes = {
  page: PropTypes.string,
};
